.App {
  font-family: 'Lato', arial, sans-serif;
  color: #434343;
  background: #DAE3E7;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App-header {
  padding: 30px 0;
  background: #f5f5f5;
  border-top: 10px solid #778492;
}

.App-header .profile-image {
  margin-right: 30px;
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
}

.App-header .btn {
  margin-top: 60px;
  font-weight: bold;
}

.App-header .profile-content .name {
  color: #49515a;
  font-size: 38px;
  margin-bottom: 5px;
  margin-top: 30px;
}

.App-header .profile-content .desc {
  color: #778492;
  font-family: "Lato", arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  margin-top: 0;
  margin-bottom: 15px;
}

.App-header .profile-content .social a {
  background: #b0b7bf;
  width: 36px;
  height: 36px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  color: #fff;
  text-align: center;
}

.App-header .profile-content .social a:hover {
  background: #778492;
}

.App-header .profile-content .social a .svg-inline--fa {
  font-size: 20px;
  margin-top: 8px;
}

a {
  color: #3AAA64;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
}

a:hover {
  text-decoration: underline;
  color: #5f6b77;
  color: #2d844e;
}

a:focus {
  text-decoration: none;
}

a.btn {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  font-family: 'Montserrat', arial, sans-serif;
  padding: 8px 16px;
  font-weight: bold;
}

a.btn {
  margin-right: 5px;
  position: relative;
  top: -2px;
}

a.btn:focus {
  color: #fff;
}


a.btn-cta-primary {
  background: #54ba4e;
  border: 1px solid #54ba4e;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

a.btn-cta-primary:hover {
  background: #49ac43;
  border: 1px solid #49ac43;
  color: #fff;
}

.sections-wrapper {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section {
  margin-bottom: 30px;
}

.section .section-inner {
  background: #fff;
  padding: 30px;
}

.section h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  color: #778492;
}

.section .heading {
  margin-top: 0;
  margin-bottom: 30px;
  color: #545e69;
  font-size: 24px;
}

.section .content .more-link .svg-inline--fa {
  margin-right: 5px;
  font-size: 14px;
  position: relative;
  top: -2px;
}

.info .svg-inline--fa {
  margin-right: 15px;
  color: #ccd1d6;
}

.info .svg-inline--fa.fa-envelope-o {
  font-size: 14px;
}

.info ul {
  margin-bottom: 0;
}

.info li {
  margin-bottom: 15px;
}

.info li:last-child {
  margin-bottom: 0;
}

.skill {
  margin-bottom: 30px;
}

.skill .title {
  font-size: 23 px;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 1.5;
}

.experience .item {
  margin-bottom: 30px;
}

.experience .item .title {
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 1.5;
}

.experience .item .title .place {
  color: #999;
  font-weight: normal;
}

.experience .item .title .place a {
  color: #999;
}

.experience .item .title .place a:hover {
  color: #666666;
}

.experience .item .title .year {
  color: #999;
  font-weight: normal;
}


.education .item {
  margin-bottom: 30px;
}

.education .item:last-child {
  margin-bottom: 0;
}

.education .item .title {
  font-size: 16px;
  margin-top: 0;
}

.education .item .university {
  font-family: "Lato", arial, sans-serif;
  font-size: 13px;
  color: #999;
  font-weight: 600;
  padding-left: 25px;
}

.education .item .university .year {
  color: #b0b7bf;
  font-weight: 500;
}

.languages .item {
  margin-bottom: 15px;
}

.languages .item .title {
  color: #778492;
}

.languages .item .level {
  color: #999;
}

.languages .item:last-child {
  margin-bottom: 0;
}

.languages .item .svg-inline--fa {
  color: #79d19a;
}

.aside .subheading {
  font-size: 18px;
  color: #545e69;
}

@media (max-width: 767.98px) {
  .header {
    text-align: center;
  }
  .header .profile-image {
    float: none !important;
    margin: 0 auto;
  }
  .header .profile-content {
    float: none !important;
    text-align: center;
  }
  .header .btn {
    margin-top: 30px;
    float: none !important;
  }
  .project-image {
    margin-bottom: 15px;
  }
}

@media (min-width: 1400px) {
  .container {
    width: 1360px;
    max-width: inherit;
  }
}
